import React, { Component, Fragment } from 'react';
import { setConfiguration } from 'react-grid-system';

import SEO from '../components/SEO';
import HeadingTeams from '../blocks/HeadingTeams';
import Teams from '../blocks/Teams';
import MobilePromo from '../blocks/MobilePromo';
import Footer from '../components/Footer';

import '../style/index.scss';

export default class TeamsPage extends Component {
  constructor(...args) {
    super(...args);

    setConfiguration({
      breakpoints: [576, 768, 980, 1280],
      containerWidths: [536, 760, 980, 1260],
      gutterWidth: 20
    });
  }

  render() {
    return (
      <Fragment>
        <SEO />

        <HeadingTeams />

        <Teams />

        <MobilePromo />

        <Footer />
      </Fragment>
    );
  }
}
